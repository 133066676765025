<template>
  <div class="card not-testing-page">
    <div v-if="!isPreloader">Нет активного прохождения</div>
    <div v-else><preloader /></div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Preloader from "../../components/Technical/Preloader.vue";
export default {
  components: { Preloader },
  name: "not-testing-page",
  setup() {
    const store = useStore(),
      router = useRouter(),
      code = computed(() => store.state.test.codeCurrent),
      isPreloader = ref(true);
    onBeforeMount(() => {
      store.dispatch("test/getCodeCurrent").then(() => {
        if (!code.value.code) isPreloader.value = false;
        else router.push(`/passing/${code.value.code}`);
      });
    });

    return {
      code,
      isPreloader,
      router,
    };
  },
};
</script>

<style lang="scss" scoped>
.not-testing-page {
  width: 100%;
  height: 94%;
  text-align: center;
  padding: 20px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>